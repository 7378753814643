// src/components/clubs/BooksSection.jsx
import React, { useState, useRef, useEffect } from 'react';
import './BooksSection.css';
import BookCard from '../book/BookCard';

const BooksSection = ({ books, title, subtitle }) => {
  const [showAll, setShowAll] = useState(false);
  const [visibleBooksCount, setVisibleBooksCount] = useState(0);
  const gridRef = useRef(null);

  const handleToggleShowMore = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const updateVisibleBooksCount = () => {
    if (gridRef.current) {
      const computedStyle = window.getComputedStyle(gridRef.current);
      const columns = computedStyle.getPropertyValue('grid-template-columns');
      const numberOfColumns = columns.split(' ').length;
      setVisibleBooksCount(numberOfColumns);
    }
  };

  useEffect(() => {
    updateVisibleBooksCount();
    window.addEventListener('resize', updateVisibleBooksCount);
    return () => {
      window.removeEventListener('resize', updateVisibleBooksCount);
    };
  }, [books]);

  return (
    <section className="books-section">
      <h2>{title}</h2>
      {subtitle && <h3 className="books-subtitle">{subtitle}</h3>}
      {books.length === 0 ? (
        <p className="no-books-text">Книги отсутствуют</p>
      ) : (
        <>
          <div className="books-grid" ref={gridRef}>
            {(showAll ? books : books.slice(0, visibleBooksCount)).map((book, index) => (
              <BookCard key={index} book={book} index={index} />
            ))}
          </div>
          {books.length > visibleBooksCount && (
            <div className="button-standard">
              <button onClick={handleToggleShowMore}>
                {showAll ? 'Свернуть' : 'Показать все'}
              </button>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default BooksSection;
