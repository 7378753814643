import React from 'react';
import './ClubCard.css';
import { Link } from 'react-router-dom';
import BookCard from '../book/BookCard';

const ClubCard = ({ club }) => {
  const { id, image, name, description, city, membersCount, readingBook, readingBookAuthor, bookImage } = club;

  const book = {
    title: readingBook,
    author: readingBookAuthor,
    book_cover: bookImage,
  };

  return (
    <div className="club-card">
      <Link to={`/clubs/${id}`} className="club-card__link">
        <div className="club-card__image-container">
          <img src={image || '/images/placeholder_club.png'} alt={name} className="club-card__image" />
        </div>
        <div className="club-card__content">
          <h3 className="club-card__name">{name}</h3>
          <p className="club-card__description">{description}</p>
          <p className="club-card__city"><strong>Город:</strong> {city}</p>
          <p className="club-card__members"><strong>Участники:</strong> {membersCount}</p>
          <div className="club-card__reading-section">
            <h4 className="club-card__reading-header">Читаем сейчас:</h4>
            <div className="club-card__book-container">
              <BookCard book={book} index={1}/>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ClubCard;
