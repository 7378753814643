import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <section className="about-us-section">
      <div className="about-us-item">
        <div className="about-us-item__text">
          <h2>
            Наша <span className="highlight-mission"><strong>миссия</strong></span>
          </h2>
          <p>
            <strong>Вдохновить</strong> каждого найти свою книгу, которая затронет душу,
            и создать сообщество людей, объединённых <strong>любовью к чтению.</strong>
            <br /><br />
            Мы стремимся не только развивать привычку к чтению, но и помогать <strong>расти как личность</strong>,
            развивая умение сочувствовать, общаться и критически мыслить.
            <br /><br />
            Вместе мы создаем сообщество, где чтение становится путем к самопознанию и обогащению жизни.
          </p>
        </div>
        <div className="about-us-item__image-container">
          <img src="images/slide_about_us.jpg" alt="Наша миссия" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
