import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Формируем данные в формате form-urlencoded
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch('${apiUrl}/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      });

      if (response.ok) {
        // Обработка успешного входа
        const data = await response.json();
        console.log('Вход выполнен успешно');
        // Перенаправляем на страницу пользователя по его ID
        navigate(`/users/${data.user_id}`);
      } else {
        // Обработка ошибок
        const errorData = await response.json();
        setError(errorData.detail || 'Ошибка входа');
      }
    } catch (err) {
      setError('Ошибка сети, попробуйте еще раз');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="login-form">
          <h1 className="login-title">Вход</h1>
          <form onSubmit={handleLogin}>
            <div className="login-input-group">
              <label>Имя пользователя</label>
              <input
                type="text"
                placeholder="Введите ваше имя пользователя"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="login-input-group">
              <label>Пароль</label>
              <input
                type="password"
                placeholder="Введите ваш пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="login-options">
              <div className="login-remember-me">
                <input type="checkbox" id="remember-me" />
                <label htmlFor="remember-me">Запомнить меня</label>
              </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="login-button">
              Войти
            </button>
          </form>
        </div>
        <div className="login-image">
          <img src="/images/clubs.jpeg" alt="Рабочий стол с ноутбуком" />
        </div>
      </div>
    </div>
  );
};

export default Login;
