// src/components/clubs/ClubsCatalog.jsx
import React, { useState } from 'react';
import ClubCard from './ClubCard';
import './ClubsCatalog.css';

const ClubsCatalog = ({ clubs }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const displayedClubs = isExpanded ? clubs : clubs.slice(0, 3);

  const toggleClubsVisibility = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="clubs-catalog">
      <h2 className="clubs-catalog__title">Каталог клубов</h2>
      <div className="clubs-catalog__grid">
        {displayedClubs.map((club) => (
          <ClubCard key={club.id} club={club} />
        ))}
      </div>
      {clubs.length > 3 && (
        <div className="clubs-catalog__more">
          <button onClick={toggleClubsVisibility} className="clubs-catalog__button">
            {isExpanded ? 'Свернуть' : 'Показать больше'}
          </button>
        </div>
      )}
    </section>
  );
};

export default ClubsCatalog;
