import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Логотип и название */}
        <a href="/" className="footer-logo">
          <img src="/images/reading-book.png" alt="Bookclub Logo" className="logo-image" />
          <div className="colored-logo">
            <span className="black-text">Book</span>
            <span className="red-text">lub</span>
          </div>
        </a>

        {/* Социальные сети */}
        {/* Раскомментируйте этот блок, если хотите отображать социальные иконки */}
        {/* <div className="footer-social">
          <a href="https://t.me/enlightenedreading" target="_blank" rel="noopener noreferrer">
            <img src="/images/telegram.png" alt="Telegram" />
          </a>
          <a href="https://www.instagram.com/vikuleeek/" target="_blank" rel="noopener noreferrer">
            <img src="/images/instagram.png" alt="Instagram" />
          </a>
        </div> */}

        {/* Авторское право */}
        <div className="footer-copyright">
          <p>&copy; 2024 Booklub. Все права защищены.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
