import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HeroProfileSection from '../components/profile/HeroProfileSection';
import Navbar from '../components/common/Navbar';
import AboutMe from '../components/profile/AboutMe';
import Footer from '../components/common/Footer';
import './ProfilePage.css'; // Импортируем стили для ProfilePage

const UserProfilePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { userId } = useParams();
  const [userProfile, setUserProfile] = useState({
    fullName: 'Имя Фамилия', // Плейсхолдер для имени
    city: 'Город', // Плейсхолдер для города
    avatar: '/images/placeholder_avatar.png', // Плейсхолдер для аватара
    aboutMe: 'Информация обо мне пока не добавлена.', // Плейсхолдер для блока "Обо мне"
    currentBook: 'Название книги отсутствует', // Плейсхолдер для названия книги
    bookImage: '/images/placeholder_book.png', // Плейсхолдер для изображения книги
    bookAuthor: 'Автор неизвестен', // Плейсхолдер для автора книги
    bookDescription: 'Описание книги отсутствует.', // Плейсхолдер для описания книги
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Получение данных профиля с сервера
    fetch(`${apiUrl}/v1/users/${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка при загрузке данных профиля');
        }
        return response.json();
      })
      .then((data) => {
        const avatarUrl = data.photo
          ? `${apiUrl}/uploads/${data.photo}`
          : '/images/placeholder_avatar.png'; // Используем плейсхолдер, если фото нет
        const bookUrl = data.current_reading_photo
          ? `${apiUrl}/uploads/${data.current_reading_photo}`
          : '/images/placeholder_book.png'; // Используем плейсхолдер, если фото книги нет

        setUserProfile({
          fullName: `${data.first_name || 'Имя'} ${data.last_name || 'Фамилия'}`,
          city: data.city || 'Город не указан',
          avatar: avatarUrl,
          aboutMe: data.about_me || 'Пользователь пока не добавил информацию о себе.',
          currentBook: data.current_reading_title || 'Название книги не указано',
          bookImage: bookUrl,
          bookAuthor: data.current_reading_author || 'Автор не указан',
          bookDescription: data.current_reading_description || 'Описание книги отсутствует.',
        });
      })
      .catch((error) => console.error('Ошибка при загрузке данных профиля:', error));
  }, [userId]);

  return (
    <div>
      <Navbar />
      <HeroProfileSection
        fullName={userProfile.fullName}
        city={userProfile.city}
        avatar={userProfile.avatar}
      />
      
      <AboutMe
        currentBook={userProfile.currentBook}
        bookImage={userProfile.bookImage}
        bookAuthor={userProfile.bookAuthor}
        bookDescription={userProfile.bookDescription}
        aboutMe={userProfile.aboutMe}
      />
      <div className="edit-profile-button-container">
        <button onClick={() => navigate(`/users/${userId}/edit`)} className="edit-profile-button">
          Редактировать профиль
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default UserProfilePage;
