// src/pages/EventPage.jsx
import React from 'react';
import Navbar from '../components/common/Navbar';
import HeroSection from '../components/common/HeroSection';
// import AboutEvent from '../components/events/AboutEvent';
// import Testimonials from '../components/common/Testimonials';
import Footer from '../components/common/Footer';

const EventPage = ({ match }) => {
  const { eventId } = match.params;
  // Fetch event data using eventId if necessary

  return (
    <>
      <Navbar />
      <HeroSection
        image="/images/event-hero.jpg"
        title="Название Мероприятия"
        description="Описание мероприятия"
      />
      {/* <AboutEvent eventId={eventId} />
      <Testimonials eventId={eventId} /> */}
      <Footer />
    </>
  );
};

export default EventPage;
