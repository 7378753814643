import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="navbar">
      <a href="/" className="navbar__logo">
        <img src="/images/reading-book.png" alt="Bookclub Logo" className="logo-image" />
        <div className="colored-logo">
          <span>Book</span>
          <span>lub</span>
        </div>
      </a>
      {/* <div className="navbar__toggle" onClick={() => setMenuOpen(!menuOpen)}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div> */}
      {/* <ul className={`navbar__links ${menuOpen ? 'active' : ''}`}>
        <li><a href="/clubs">Книжные клубы</a></li>
        <li><a href="/events">Мероприятия</a></li>
      </ul> */}
    </nav>
  );
};

export default Navbar;
