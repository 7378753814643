import React, { useState } from 'react';
import './Register.css';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [name, setName] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null); // Проверка доступности имени
  const [telegram, setTelegram] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  // Функция проверки доступности имени пользователя
  const checkUsernameAvailability = async (username) => {
    try {
      const response = await fetch(`${apiUrl}/v1/check_username?username=${username}`);
      if (response.ok) {
        setIsUsernameAvailable(true); // Имя пользователя доступно
      } else {
        setIsUsernameAvailable(false); // Имя пользователя занято
      }
    } catch (err) {
      setError('Ошибка сети при проверке имени, попробуйте еще раз');
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (value.length > 3) { // Проверяем доступность только если введено более 3 символов
      checkUsernameAvailability(value);
    } else {
      setIsUsernameAvailable(null);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Проверка совпадения паролей
    if (password !== confirmPassword) {
      setError('Пароли не совпадают');
      return;
    }

    // Проверка доступности имени пользователя
    if (isUsernameAvailable === false) {
      setError('Имя пользователя уже занято');
      return;
    }

    setError(''); // Сброс ошибки, если все верно

    // Отправка данных на бэкенд
    try {
      const response = await fetch('${apiUrl}/v1/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: name,
          telegram: telegram,
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        // Обработка успешной регистрации
        const userData = await response.json();
        // Перенаправляем на страницу профиля пользователя по его ID
        navigate(`/users/${userData.id}`);
        console.log('Регистрация успешна');
      } else {
        // Обработка ошибок
        const errorData = await response.json();
        setError(errorData.detail || 'Ошибка регистрации');
      }
    } catch (err) {
      setError('Ошибка сети, попробуйте еще раз');
    }
  };

  return (
    <div className="register-container">
      <div className="register-form-container">
        <div className="register-form">
          <h1 className="register-title">Регистрация</h1>
          <form onSubmit={handleRegister}>
            <div className="register-input-group">
              <label>Имя пользователя</label>
              <input 
                type="text" 
                placeholder="Введите ваше имя пользователя"
                value={name}
                onChange={handleUsernameChange}
                required
              />
              {isUsernameAvailable === false && (
                <p className="error-message">Имя пользователя уже занято</p>
              )}
            </div>
            <div className="register-input-group">
              <label>Телеграмм</label>
              <input 
                type="text" 
                placeholder="Введите ваш Telegram ник"
                value={telegram}
                onChange={(e) => setTelegram(e.target.value)}
              />
            </div>
            <div className="register-input-group">
              <label>Электронная почта *</label>
              <input 
                type="email" 
                placeholder="Введите вашу почту"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="register-input-group">
              <label>Пароль *</label>
              <input 
                type="password" 
                placeholder="Введите ваш пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="register-input-group">
              <label>Повторите пароль *</label>
              <input 
                type="password" 
                placeholder="Повторите ваш пароль"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            {/* Отображение ошибки, если пароли не совпадают или имя занято */}
            {error && <p className="error-message">{error}</p>}

            <button type="submit" className="register-button">
              Зарегистрироваться
            </button>
          </form>
        </div>
        <div className="register-image">
          <img src="/images/clubs.jpeg" alt="Books" />
        </div>
      </div>
    </div>
  );
};

export default Register;
