import React from 'react';
import './AboutMe.css';

const AboutMe = ({ currentBook, bookImage, bookAuthor, bookDescription, aboutMe }) => {
  return (
    <section className="about-me-section">
      {/* Читаю сейчас */}
      <div className="current-book-section">
        <h2>Читаю сейчас</h2>
        <div className="book-details">
          <img src={bookImage} alt={`Обложка книги ${currentBook}`} className="book-cover" />
          <div className="book-info">
            <p>{currentBook}</p>
            <p className="author">Автор: {bookAuthor}</p>
            <p>{bookDescription}</p>
          </div>
        </div>
      </div>

      {/* Обо мне */}
      <div className="about-me-section-content">
        <h2>Обо мне</h2>
        <p>{aboutMe}</p>
      </div>
    </section>
  );
};

export default AboutMe;
