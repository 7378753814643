// src/pages/ClubPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/common/Navbar';
import HeroSection from '../components/common/HeroSection';
import AboutClub from '../components/clubs/AboutClub';
import Footer from '../components/common/Footer';
import BooksSection from '../components/clubs/BooksSection';
import EventCatalog from '../components/events/EventCatalog';

const ClubPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { clubId } = useParams();
  const navigate = useNavigate();
  const [clubData, setClubData] = useState(null);
  const [currentBook, setCurrentBook] = useState(null);
  const [finishedBooks, setFinishedBooks] = useState([]);
  const [futureBooks, setFutureBooks] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Функция для обработки книги и добавления полного пути к обложке
    const processBookData = (book) => ({
      ...book,
      book_cover:
        book.book_cover && book.book_cover !== 'null'
          ? `${apiUrl}/uploads/${book.book_cover}`
          : '/images/placeholder_book.png',
    });

    // Запрос данных клуба
    fetch(`${apiUrl}/v1/clubs/${clubId}`)
      .then((response) => response.json())
      .then((data) => setClubData(data))
      .catch((error) => console.error('Ошибка при получении данных клуба:', error));

    // Запрос текущей книги клуба
    fetch(`${apiUrl}/v1/books/currently-reading/${clubId}`)
      .then((response) => (response.ok ? response.json() : null))
      .then((data) => {
        if (data) {
          setCurrentBook(processBookData(data));
        } else {
          setCurrentBook(null);
        }
      })
      .catch((error) => {
        console.error('Ошибка при получении текущей книги:', error);
      });

    // Запрос прочитанных книг клуба
    fetch(`${apiUrl}/v1/books/finished-books/${clubId}`)
      .then((response) => response.json())
      .then((data) => {
        const processedBooks = data.map(processBookData);
        setFinishedBooks(processedBooks);
      })
      .catch((error) => console.error('Ошибка при получении прочитанных книг клуба:', error));

    // Запрос будущих книг клуба
    fetch(`${apiUrl}/v1/books/future-books/${clubId}`)
      .then((response) => response.json())
      .then((data) => {
        const processedBooks = data.map(processBookData);
        setFutureBooks(processedBooks);
      })
      .catch((error) => console.error('Ошибка при получении будущих книг клуба:', error));

    // Запрос списка мероприятий клуба
    fetch(`${apiUrl}/v1/events/club/${clubId}`)
      .then((response) => response.json())
      .then((data) => setEvents(data))
      .catch((error) => console.error('Ошибка при получении мероприятий клуба:', error));
  }, [clubId, apiUrl]);

  const handleJoinClub = () => {
    console.log('Пользователь хочет присоединиться к клубу!');
  };

  const handleBackToClubs = () => {
    navigate('/clubs');
  };

  return (
    <>
      <Navbar />
      {clubData ? (
        <>
          <HeroSection
            images={[
              clubData.poster_url && clubData.poster_url !== 'null'
                ? `${apiUrl}/uploads/${clubData.poster_url}`
                : `${apiUrl}/uploads/placeholder_club.png`,
            ]}
            title={clubData.name}
            description={clubData.description}
          />
          <AboutClub
            clubName={clubData.name}
            clubDescription={clubData.about_club}
            bookImage={
              currentBook && currentBook.book_cover
                ? currentBook.book_cover
                : '/images/placeholder_book.png'
            }
            bookTitle={currentBook ? currentBook.title : 'Нет текущей книги'}
            bookAuthor={currentBook ? currentBook.author : ''}
            onJoinClub={handleJoinClub}
          />
          <BooksSection books={futureBooks} title="Будущие книги" />
          <BooksSection books={finishedBooks} title="Книги, которые мы прочитали" />
          <EventCatalog events={events} title="Наши мероприятия" />
          <button className="back-to-clubs-button-scroll" onClick={handleBackToClubs}>
            К списку клубов
          </button>
          <Footer />
        </>
      ) : (
        <p>Загрузка...</p>
      )}
    </>
  );
};

export default ClubPage;
