// OurOffers.js
import React, { useState } from 'react';
import './OurOffers.css';
import '../../index.css';
import { Link } from 'react-router-dom';
import Modal from '../common/Modal';

const OurOffers = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isInterestModalOpen, setInterestModalOpen] = useState(false);

  const handleCreateClub = (data) => {
    console.log("Club data submitted:", data);
  };

  const handleInterestSubmit = (data) => {
    console.log("Interests submitted:", data);
  };

  return (
    <section className="offers-section">
      <div className="offers-header">
        <h1>Сделайте чтение проще и интереснее</h1>
        <p>Создавайте клубы, присоединяйтесь к обсуждениям и находите единомышленников в одном месте.</p>
      </div>
      <div className="offers-grid">
        <div className="offer-card">
          <div className="card-content">
            <div className="card-icon">
              <img src="images/search.png" alt="Join clubs icon" />
            </div>
            <h3 className="card-title">Присоединяйся к клубам</h3>
            <p className="card-text">Находите интересные клубы по жанрам и темам, и присоединяйтесь к обсуждениям.</p>
            <Link to="/clubs" className="button-standard">Найти клуб</Link>
          </div>
        </div>
        <div className="offer-card">
          <div className="card-content">
            <div className="card-icon">
              <img src="images/reading-book.png" alt="Create your club icon" />
            </div>
            <h3 className="card-title">Создай свой клуб</h3>
            <p className="card-text">Создайте свой собственный книжный клуб и пригласите друзей для совместного чтения.</p>
            <button className="button-standard" onClick={() => setModalOpen(true)}>Создать</button>
          </div>
        </div>
        <div className="offer-card">
          <div className="card-content">
            <div className="card-icon">
              <img src="images/appointment-book.png" alt="Find like-minded people icon" />
            </div>
            <h3 className="card-title">Найди единомышленников</h3>
            <p className="card-text">Найдите людей с такими же интересами и начните читать вместе.</p>
            <button className="button-standard" onClick={() => setInterestModalOpen(true)}>Подробнее</button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          onSubmit={handleCreateClub}
          title="Создай свой клуб"
          description = "Заполните информацю о вашем клубе: онлайн или офлайн ваш клуб проводит встречи; в каком городе; какие книги вы прочитали или планируете прочитать."
          fields={[
            { name: "clubName", placeholder: "Название клуба", type: "text" },
            { name: "description", placeholder: "Описание", type: "textarea" },
            { name: "telegram", placeholder: "Телеграм", type: "text" }
          ]}
          buttonLabel="Создать"
          messagePrefix="[СОЗДАНИЕ НОВОГО КЛУБА]"
        />
      )}

      {isInterestModalOpen && (
        <Modal
          onClose={() => setInterestModalOpen(false)}
          onSubmit={handleInterestSubmit}
          title="Найди единомышленников"
          description = "Заполните заявку, и мы опубликуем ее на сайте для поиска единомышленников."
          fields={[
            { name: "clubName", placeholder: "Название книги", type: "text" },
            { name: "description", placeholder: "Описание", type: "textarea" }
          ]}
          buttonLabel="Создать"
          messagePrefix="[ПОИСК КЛУБА]"
        />
      )}
    </section>
  );
};

export default OurOffers;
