import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ProfileEditPage.css';

const ProfileEditPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { userId } = useParams();
  const parsedUserId = parseInt(userId, 10);
  console.log('parsedUserId:', parsedUserId);
  const navigate = useNavigate();
  
  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    city: '',
    photo: null,
    about_me: '',
    current_reading_photo: null, // Изначально null
    current_reading_title: '',
    current_reading_author: '',
    current_reading_description: '',
    user_id: parsedUserId,
  });
  
  const [previewImage, setPreviewImage] = useState(null);
  const [bookPreviewImage, setBookPreviewImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (isNaN(parsedUserId)) {
      console.error("Ошибка: userId должен быть целым числом");
      return;
    }

    fetch(`${apiUrl}/v1/users/${parsedUserId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Ошибка при загрузке данных профиля');
        }
        return response.json();
      })
      .then(data => {
        setProfileData(data);
        setPreviewImage(data.photo ? `${apiUrl}/uploads/${data.photo}` : null);
        setBookPreviewImage(data.current_reading_photo ? `${apiUrl}/uploads/${data.current_reading_photo}` : null);
        console.log('URL для current_reading_photo:', `${apiUrl}/uploads/${data.current_reading_photo}`);
        console.log('current_reading_photo:', data.current_reading_photo);
      })
      .catch(error => console.error('Ошибка при загрузке данных профиля:', error));
  }, [parsedUserId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log('Загружаемый файл профиля:', file);
    if (file) {
      setProfileData(prevState => ({
        ...prevState,
        photo: file
      }));
  
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Предварительный просмотр профиля:', reader.result);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBookImageUpload = (e) => {
    const file = e.target.files[0];
    console.log('Загружаемое фото книги:', file);
    if (file) {
      setProfileData(prevState => ({
        ...prevState,
        current_reading_photo: file
      }));
  
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Предварительный просмотр книги:', reader.result);
        setBookPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('first_name', profileData.first_name);
    formData.append('last_name', profileData.last_name);
    formData.append('city', profileData.city);
    formData.append('about_me', profileData.about_me);
    formData.append('current_reading_title', profileData.current_reading_title);
    formData.append('current_reading_author', profileData.current_reading_author);
    formData.append('current_reading_description', profileData.current_reading_description);
  
    if (profileData.photo instanceof File) {
      formData.append('photo', profileData.photo);
    }

    if (profileData.current_reading_photo instanceof File) {
      formData.append('current_reading_photo', profileData.current_reading_photo);
    }

    fetch(`${apiUrl}/v1/users/${parsedUserId}`, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка при обновлении профиля');
        }
        return response.json();
      })
      .then((data) => {
        setSuccessMessage('Профиль успешно обновлен!');
        setTimeout(() => {
          navigate(`/users/${parsedUserId}`);
        }, 2000);
      })
      .catch((error) => console.error('Ошибка при обновлении профиля:', error));
  };
  
  return (
    <div className="profile-edit-page">
      <h1 className="edit-profile-title">Редактировать профиль</h1>
      {successMessage && <p className="success-message">{successMessage}</p>}
      <form onSubmit={handleSubmit} className="profile-edit-form">
        <div className="form-group">
          <label htmlFor="first_name">Имя:</label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={profileData.first_name}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="last_name">Фамилия:</label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={profileData.last_name}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">Город:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={profileData.city}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="about_me">Обо мне:</label>
          <textarea
            id="about_me"
            name="about_me"
            value={profileData.about_me}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="current_reading_title">Название книги:</label>
          <input
            type="text"
            id="current_reading_title"
            name="current_reading_title"
            value={profileData.current_reading_title}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="current_reading_author">Автор книги:</label>
          <input
            type="text"
            id="current_reading_author"
            name="current_reading_author"
            value={profileData.current_reading_author}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="current_reading_description">Описание книги:</label>
          <textarea
            id="current_reading_description"
            name="current_reading_description"
            value={profileData.current_reading_description}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="photo">Фото профиля:</label>
          <input
            type="file"
            id="photo"
            onChange={handleImageUpload}
            className="form-control"
          />
          {previewImage && (
            <img src={previewImage} alt="Предварительный просмотр" className="profile-preview-img" />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="book_photo">Фото книги:</label>
          <input
            type="file"
            id="book_photo"
            onChange={handleBookImageUpload}
            className="form-control"
          />
          {bookPreviewImage && (
            <img src={bookPreviewImage} alt="Предварительный просмотр книги" className="profile-preview-img" />
          )}
        </div>
        <div className="button-group">
          <button type="button" className="cancel-button" onClick={() => navigate(`/users/${parsedUserId}`)}>Отмена</button>
          <button type="submit" className="save-button">Сохранить изменения</button>
        </div>
      </form>
    </div>
  );
};

export default ProfileEditPage;
