// src/pages/ClubsCatalogPage.jsx
import React, { useState, useEffect } from 'react';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import HeroSection from '../components/common/HeroSection';
import ClubsCatalog from '../components/clubs/ClubsCatalog';

const ClubsCatalogPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [clubs, setClubs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClubsData = async () => {
      try {
        const response = await fetch(`${apiUrl}/v1/clubs/`);
        if (!response.ok) {
          throw new Error(`Ошибка загрузки клубов: ${response.statusText}`);
        }
        const clubsData = await response.json();

        const formattedClubs = await Promise.all(
          clubsData.map(async (club) => {
            let readingBook = 'Нет текущей книги';
            let readingBookAuthor = 'Автор не указан';
            let bookImage = `${apiUrl}/uploads/placeholder_book.png`;

            try {
              const bookResponse = await fetch(`${apiUrl}/v1/books/currently-reading/${club.id}`);
              if (bookResponse.ok) {
                const bookData = await bookResponse.json();
                readingBook = bookData.title || readingBook;
                readingBookAuthor = bookData.author || readingBookAuthor;
                bookImage =
                  bookData.book_cover && bookData.book_cover !== 'null'
                    ? `${apiUrl}/uploads/${bookData.book_cover}`
                    : bookImage;
              }
            } catch (error) {
              console.error(`Ошибка при загрузке текущей книги для клуба ${club.id}:`, error);
            }

            return {
              id: club.id,
              image:
                club.poster_url && club.poster_url !== 'null'
                  ? `${apiUrl}/uploads/${club.poster_url}`
                  : `${apiUrl}/uploads/placeholder_club.png`,
              name: club.name,
              description: club.description,
              city: club.city,
              membersCount: club.max_members,
              readingBook,
              readingBookAuthor,
              bookImage,
            };
          })
        );

        setClubs(formattedClubs);
      } catch (error) {
        console.error("Ошибка при загрузке данных клубов:", error);
        setError(error);
      }
    };

    fetchClubsData();
  }, [apiUrl]);

  return (
    <>
      <Navbar />
      <HeroSection
        images={['/images/clubs_hero.jpeg']}
        title="Найдите свое книжное сообщество."
        description="Отыщите идеальный для себя клуб в списке доступных — все они открыты для новых участников. Хотите ли вы общаться онлайн или встречаться офлайн в своем городе, углубиться в изучение истории, читать классику или фантастику, исследовать другие культуры — для вас непременно найдётся сообщество по душе.

Присоединяйтесь и разделите радость чтения с единомышленниками!"
        buttonText="Найти клуб"
        buttonLink="#clubs"
      />
      <div id="clubs" className="club-cards-container">
        {error ? (
          <p>Произошла ошибка при загрузке клубов. Пожалуйста, попробуйте позже.</p>
        ) : (
          <ClubsCatalog clubs={clubs} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default ClubsCatalogPage;
