// src/pages/EventsCatalogPage.jsx
import React from 'react';
import Navbar from '../components/common/Navbar';
import HeroSection from '../components/common/HeroSection';
import EventCatalog from '../components/events/EventCatalog';
import Footer from '../components/common/Footer';


const EventsCatalogPage = () => {
  const eventsData = [
    {
      image: '/images/slide5.jpg',
      title: 'Global Online Event',
      city: 'Москва',
      date: '23 сентября, 2024',
      price: 500,
    },
    {
      image: '/images/slide2.jpg',
      title: 'Ultra Processed People',
      city: 'Лондон',
      date: '24 сентября, 2024',
      price: 800,
    },
    {
      image: '/images/slide3.jpg',
      title: 'Book Festival',
      city: 'Берлин',
      date: '25 сентября, 2024',
      price: 300,
    },
    {
      image: '/images/slide4.jpg',
      title: 'Reading Conference',
      city: 'Нью-Йорк',
      date: '26 сентября, 2024',
      price: 0,
    },
    {
      image: '/images/slide5.jpg',
      title: 'Literary Festival',
      city: 'Париж',
      date: '27 сентября, 2024',
      price: 1000,
    },
  ];

  return (
    <>
      <Navbar />
      <HeroSection 
        images={[
          '/images/club1.jpg',
        ]}
        title="Книжные мероприятия"
        description="Присоединяйся к нашей компании в будущих вечеринках"
        buttonText="Найти мероприятие"
        buttonLink="#events"
      />
      <EventCatalog events={eventsData} />
      
      <Footer />
    </>
  );
};

export default EventsCatalogPage;
