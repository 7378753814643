// src/pages/AuthPage.jsx
import React from 'react';
import { useParams } from 'react-router-dom'; // Импортируем useParams
import Navbar from '../components/common/Navbar';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import Footer from '../components/common/Footer';

const AuthPage = () => {
  const { authType } = useParams(); // Получаем параметр из URL

  // Проверяем, на какой странице находится пользователь
  const isLogin = authType === 'login'; // Если URL содержит 'login', показываем компонент Login

  return (
    <>
      <Navbar />
      {isLogin ? <Login /> : <Register />}
      <Footer />
    </>
  );
};

export default AuthPage;
