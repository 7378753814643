import React, { useEffect, useState } from 'react';
import './HeroSection.css';

const HeroSection = ({ images, title, description, buttonText, buttonLink }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Меняем изображение каждые 8 секунд

    return () => clearInterval(interval); // Очищаем интервал при размонтировании компонента
  }, [images.length]);

  const handleClick = (e) => {
    if (buttonLink.startsWith('#')) {
      e.preventDefault(); // Предотвращаем переход по ссылке
      const targetElement = document.querySelector(buttonLink);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <section
      className="hero-section"
      style={{ '--background-image': `url(${images[currentImage]})` }}
    >
      <div className="hero-section__content">
        <h1>{title}</h1>
        <p>{description}</p>
        {/* Проверка, нужно ли отображать кнопку */}
        {buttonText && buttonLink && (
          <a 
            href={buttonLink} 
            className="hero-section__button" 
            onClick={handleClick}
          >
            {buttonText}
          </a>
        )}
      </div>
    </section>
  );
};

export default HeroSection;
