// Modal.js
import React, { useState } from 'react';
import './Modal.css';
import '../../index.css';

const TOKEN = "7345268657:AAHtV9WfS4I-xQwhBgTqa5fm0D2Zoy8lrbo";
const chat_id = "-4213649559";

const Modal = ({ onClose, fields, buttonLabel, onSubmit, messagePrefix, title, description }) => {
  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {})
  );
  const [submissionMessage, setSubmissionMessage] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e, fieldName) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));
  };

  const handleSubmit = async () => {
    let formErrors = {};

    // Validate each field is filled
    fields.forEach((field) => {
      if (formData[field.name].trim() === '') {
        formErrors[field.name] = "необходимо заполнить";
      }
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Construct the message with a customizable prefix and all formData key-value pairs
    const details = Object.entries(formData)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
    const message = `${messagePrefix}\n${details}`;
    const url = `https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=${chat_id}&text=${encodeURIComponent(message)}`;

    try {
      await fetch(url); // Send request to Telegram API

      // Call the onSubmit callback to pass data to the parent component (if needed)
      onSubmit(formData);

      setSubmissionMessage("Ваша заявка принята. В ближайшее время с вами свяжется организатор.");
      setIsSubmitted(true);
      setErrors({}); // Clear errors after successful submission

      setTimeout(() => {
        setSubmissionMessage(null);
        onClose();
      }, 7000); // Auto-close after 7 seconds
    } catch (error) {
      console.error("Error sending message to Telegram:", error);
      setSubmissionMessage("Произошла ошибка. Попробуйте еще раз.");
    }
  };

  const handleClose = () => {
    setSubmissionMessage(null);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="modal-close" onClick={handleClose}>&times;</button>
        <h2>{title}</h2> {/* Use the configurable title prop */}
        {description && <p className="modal-description">{description}</p>} {/* Display description under title */}

        {fields.map((field) => (
          <div key={field.name}>
            {field.type === 'textarea' ? (
              <textarea
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={(e) => handleInputChange(e, field.name)}
                disabled={isSubmitted}
              />
            ) : (
              <input
                type={field.type}
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={(e) => handleInputChange(e, field.name)}
                disabled={isSubmitted}
              />
            )}
            {errors[field.name] && <p className="error-message">{errors[field.name]}</p>}
          </div>
        ))}

        {submissionMessage && <p className="submission-message">{submissionMessage}</p>}

        <button
          className="button-standard"
          onClick={isSubmitted ? handleClose : handleSubmit}
          disabled={isSubmitted && !submissionMessage}
        >
          {isSubmitted ? "Закрыть" : buttonLabel}
        </button>
      </div>
    </div>
  );
};

export default Modal;
