// src/pages/HomePage.jsx
import React from 'react';
import Navbar from '../components/common/Navbar';
import HeroSection from '../components/common/HeroSection';
import AboutUs from '../components/home/AboutUs';
// import HowItWorks from '../components/home/HowItWorks';
import OurOffers from '../components/home/OurOffers';
import Testimonials from '../components/common/Testimonials';
import Footer from '../components/common/Footer';

const homeTestimonials = [
  {
    title: "Анна Кримбали",
    subtitle: 'Участник клуба "Просвещённые читатели"',
    text: (
      <>
        <p>Я люблю читать и всегда ставлю перед собой небольшие цели, например, закончить книгу за две недели, хотя иногда, ввиду жизненных обстоятельств, приходится корректировать их. Эта привычка сформировалась благодаря моей работе, и я получаю удовольствие от выполнения плана.</p>
        <br></br>
        <p>Предпочитаю художественную литературу и увлекаюсь классикой благодаря нашему книжному клубу. Также много читаю бизнес-литературу для саморазвития и профессионального роста, делаю конспекты.</p>
        <br></br>
        <p>Чтение приносит мне эмоции и впечатления, и я люблю делиться прочитанным и обсуждать книги, хотя не всегда нахожу для этого собеседников.</p>
        <br></br>
        <p>Участие в книжном клубе стало для меня важным опытом: мне нравится быть частью сообщества единомышленников, это мотивирует меня читать больше и делает этот год самым продуктивным в плане чтения. </p>
        <br></br>
        <p>Душевная атмосфера, интересные встречи, игровой контент и различные мероприятия в клубе доставляют мне большое удовольствие. Раньше я даже и не думала, что можно вот так проводить время, а сейчас это часть моей обычной жизни.</p> </>
    ),
    image: "images/testimonial_1.jpg",
  },
  {
    title: "Ирина Яковлева",
    subtitle: 'Участник клуба "Просвещённые читатели"',
    text: (
      <>
        <p>Я читаю с детства, и чтение — моя ежедневная привычка. Мое образование связано с книгами, и чтение — неотъемлемая часть моей жизни.</p>
        <br></br>
        <p>Я читаю разнообразную литературу: книги, связанные с моей профессиональной деятельностью, бизнес-литература, психология, а также книги об искусстве и моде. 
        Люблю художественную литературу, и книжный клуб стал для меня поводом читать её больше.</p>
        <br></br>
        <p>Мне нравится участвовать в подобных инициативах, подобных книжному клубу. Это доставляет мне удовольствие. Интересно обсуждать прочитанное, погружаться более глубоко и детально в эпоху книги, видеть более широкую картину.</p>
        </>
    ),
    image: "images/testimonial_2.jpg",
  },
  {
    title: "Дмитрий Легчиков",
    subtitle: 'Участник клуба "Просвещённые читатели"',
    text: (
      <>
        <p>Я активно участвую в чтениях и обсуждениях книг. Совместное чтение делает процесс увлекательнее, помогает дочитать до конца и открывает новые точки зрения. Обсуждение повышает понимание книги, что отражается на учебе и работе.</p>
        <br></br>
        <p>Чтение классической литературы, по моему мнению, сильно недооценено сегодня. Большинство предпочитают бизнес-, нон-фикшн и self-help, стремясь к быстрым решениям, что мешает видеть общую картину. Классика поднимает фундаментальные проблемы человечества, актуальные и сейчас: бедность, неравенство, корысть. В отличие от бизнес-литературы, эти проблемы скрыты за сюжетом.</p>
        <br></br>
        <p>Раньше я не любил читать художественную, но понял, что сюжет — лишь видимая часть книги, за которой скрывается большая ценность. Поэтому я с удовольствием принимаю участие в книжном клубе, это мотивирует меня читать больше и глубже погружаться в литературу.</p>
        </>
    ),
    image: "images/testimonial_3.jpg",
  },
  {
    title: "Александра Горшковская",
    subtitle: 'Участник клуба "Просвещённые читатели"',
    text: (
      <>
        <p>Я обожаю читать, однако регулярно это удаётся не всегда. Обычно я либо погружаюсь в книгу с головой, если она меня сильно впечатлила, либо совсем не читаю. Часто простои случаются из-за накопившейся работы и домашних дел, а также между книгами бывает сложно переключиться на новую историю после предыдущей.</p>
        <br></br>
        <p>Мои любимые жанры — классика, психология взаимоотношений и мемуары. Я полюбила классику во взрослом возрасте за её способность раскрывать тонкую психологию и сложные взаимоотношения между людьми, будь то между мужчиной и женщиной или в отношениях с детьми. Мне также очень интересно читать дневники и мемуары — реальные истории жизни, которые позволяют глубже понять человека.</p>
        <br></br>
        <p>Классика привлекает меня тем, что в ней показана обычная жизнь без прикрас, и я люблю разбирать поступки персонажей, перенося их истории на наше время.</p>
        <br></br>
        <p>Читать я люблю, но зачастую не нахожу с кем поделиться своими мыслями. Поэтому я вступила в книжный клуб. Раньше мне было сложно сформулировать свои мысли, но в клубе я могу услышать разные точки зрения и увидеть одну и ту же ситуацию под разными углами. Это помогает мне учиться смотреть на вещи шире и собирать свои мысли воедино.</p>
        <br></br>
        <p>Мой мужчина тоже участвует в нашем книжном клубе, и мы читаем и обсуждаем книги вместе. Это сближает нас, мы открываемся друг другу с разных сторон и стали лучше понимать друг друга.</p>
        <br></br>
        <p>В целом, участие в книжном клубе обогатило мой опыт чтения и позволило глубже погрузиться в обсуждение прочитанных произведений.</p>
      </>
    ),
    image: "images/testimonial_4.jpg",
  },
  {
    title: "Виктория Легчикова",
    subtitle: 'Организатор клуба "Просвещённые читатели"',
    text: (
      <>
        <p>Я с книгой всегда. Если проходит день, когда я не читала, я чувствую себя неуютно. Чтение — это самая обычная рутина моего дня.</p>
        <br></br>
        <p>Я люблю различную литературу, но больше всего читаю художественную. Классику я обожаю за то, что её чтение позволяет не только наслаждаться произведением, но и узнавать историю, особенно историю нашего государства. Мне нравится замечать в книгах связи с другими авторами и произведениями, это мотивирует меня читать больше, чтобы сложить этот литературный пазл.</p>
        <br></br>
        <p>Отсюда и возникла идея создать книжный клуб "Просвещённые читатели", где мы через литературу узнаём больше о том времени и обсуждаем его. Горящие глаза участников и постоянные встречи вдохновляют меня продолжать работать в этом направлении и развивать клуб.</p>
        <br></br>
        <p>Быть в кругу таких же заинтересованных людей — значит чувствовать свою значимость в этом мире, и это приносит мне огромную радость. Участие в книжном клубе обогатило мой жизненный опыт и позволило глубже погрузиться в изучение литературы и истории, создавая уникальные связи и понимание между нами.</p>
     </>
    ),
    image: "images/testimonial_6.png",
  },
];

const HomePage = () => (
  <>
    <Navbar />
     <HeroSection
      images={[
        '/images/slide_hero_1.jpg',
        '/images/slide_hero_2.jpg',
        '/images/slide_hero_5.jpg',
        '/images/slide_hero_4.jpg',
        '/images/slide_hero_6.jpg',
        '/images/slide_hero_3.jpg',
        '/images/slide_hero_7.jpg',
        '/images/slide_hero_8.jpg'
      ]}
      title="Присоединяйтесь к книжным клубам или организуйте свой"
      description="Выбирайте интересные темы, организуйте свои клубы и наслаждайтесь чтением вместе с единомышленниками."
      buttonText="Присоединиться к клубу"
      buttonLink="/clubs"
    />
    <AboutUs />   
    <OurOffers />
    <Testimonials title="Отзывы наших участников" testimonials={homeTestimonials}/>
    <Footer /> 
  </>
);

export default HomePage;
