import React, { useState } from 'react';
import './Testimonials.css';

const Testimonials = ({ testimonials, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Current testimonial index

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <section className="testimonials-section">
      <h2>{title}</h2>
      <div className="testimonial-card">
        <p className="testimonial-title">{testimonials[currentIndex].title}</p>
        <p className="testimonial-subtitle">{testimonials[currentIndex].subtitle}</p>
        <p className="testimonial-text">{testimonials[currentIndex].text}</p>
        <div className="testimonial-avatar">
          <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].title} />
        </div>
      </div>
      <div className="testimonial-navigation">
        <button className="testimonial-nav-button" onClick={handlePrevClick}>❮</button>
        <button className="testimonial-nav-button" onClick={handleNextClick}>❯</button>
      </div>
    </section>
  );
};

export default Testimonials;
