// src/components/profile/HeroProfileSection.jsx
import React from 'react';
import './HeroProfileSection.css';

const HeroProfileSection = ({ fullName, city, avatar }) => {
  return (
    <section className="hero-profile-section">
      <div className="hero-profile-content">
        <div className="hero-profile-text">
          <h1 className="hero-profile-name">{fullName}</h1>
          <p className="hero-profile-city">{city}</p>
        </div>
        <div className="hero-profile-avatar">
          <img src={avatar} alt={`${fullName}'s avatar`} className="profile-avatar-img" />
        </div>
      </div>
    </section>
  );
};

export default HeroProfileSection;
