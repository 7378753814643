import React, { useState, useRef, useEffect } from 'react';
import EventCard from './EventCard';
import './EventCatalog.css';

const EventCatalog = ({ events, title }) => {
  const [showAll, setShowAll] = useState(false);
  const gridRef = useRef(null);

  const handleToggleShowMore = () => {
    setShowAll(prevShowAll => !prevShowAll);
  };

  // Фильтруем мероприятия по признаку is_upcoming
  const upcomingEvents = events.filter(event => event.is_upcoming);
  const pastEvents = events.filter(event => !event.is_upcoming);
  const eventsToShow = showAll ? events : upcomingEvents;

  return (
    <section className="event-catalog-section">
      <h2>{title}</h2>
      <div className="events-grid" ref={gridRef}>
        {eventsToShow.map((event, index) => (
          <EventCard
            key={event.id || index}
            image={event.cover_photo}
            title={event.title}
            city={event.city}
            date={event.date}
            price={event.price}
            isUpcoming={event.is_upcoming}
          />
        ))}
      </div>
      {pastEvents.length > 0 && !showAll && (
        <button className="button-standard" onClick={handleToggleShowMore}>
          Показать все
        </button>
      )}
      {showAll && (
        <button className="button-standard" onClick={handleToggleShowMore}>
          Свернуть
        </button>
      )}
    </section>
  );
};

export default EventCatalog;
