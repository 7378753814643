// src/components/events/EventCard.jsx
import React from 'react';
import './EventCard.css';

// Функция для форматирования даты в "День Месяц Год"
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  let formattedDate = date.toLocaleDateString('ru-RU', options);

  // Преобразуем первую букву месяца в заглавную
  formattedDate = formattedDate.replace(/(\d+)\s(\w+)/, (match, day, month) => {
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
    return `${day} ${capitalizedMonth}`;
  });

  return formattedDate;
};

const EventCard = ({ image, title, city, date, price, isUpcoming }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const backgroundImageUrl = image
    ? `${apiUrl}/uploads/${image}`
    : `${apiUrl}/uploads/placeholder_club.png`;

  return (
    <div className="event-card" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      {isUpcoming && <div className="event-flag">Планируется</div>}
      <div className="event-card-content">
        <h3>{title}</h3>
        <p>{city}</p>
        <p>{formatDate(date)}</p>
      </div>
    </div>
  );
};

export default EventCard;
